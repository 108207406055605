// import { ref } from "vue";
import { MutationTree } from "vuex";
// import { State } from './state';
import {
  // AssetCategory,
  // StaffingMutationTypes as MutationTypes,
  // StaffingSeverity,
  // StaffingState as State,
  // StaffingStatus,
  // StaffingType,
  FilterOptionDef, StaffingChecklist
} from "@/types/index";
import { StaffingMutationTypes as MutationTypes } from './mutation-types';
import { StaffingState as State, defaultState } from './state';
import {
  Staffing,
  FieldDef,
  FilterDef,
  SortDef,
} from "@/types/index";

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;

  [MutationTypes.SET_STAFFINGS](state: S, payload: Staffing[]): void;
  [MutationTypes.SET_IS_LOADING_STAFFINGS](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_STAFFINGS_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_STAFFINGS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_UPDATING_STAFFINGS](state: S, payload: boolean): void;
  [MutationTypes.SET_UPDATED_STAFFINGS_DATA](state: S, payload: any): void;

  [MutationTypes.SET_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_SEARCH](state: S, payload: string | null): void;
  [MutationTypes.SET_SEARCH_CHECKLIST](state: S, payload: string | null): void;
  [MutationTypes.SET_FILTER](state: S, payload: FilterDef[]): void;
  [MutationTypes.SET_AVAILABLEFIELDS](state: S, payload: FieldDef[]): void;
  [MutationTypes.SET_SORTS](state: S, payload: SortDef[]): void;
  [MutationTypes.SET_SORTS_CHECKLIST](state: S, payload: SortDef[]): void;

  [MutationTypes.SET_STAFFINGS_KANBAN](state: S, payload: Map<string, Staffing[]>): void;
  [MutationTypes.SET_IS_LOADING_STAFFINGS_KANBAN](state: S, payload: Map<string, boolean>): void;
  // [MutationTypes.SET_DELETED_STAFFINGS_KANBAN_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_STAFFINGS_KANBAN](state: S, payload: Map<string, boolean>): void;
  [MutationTypes.SET_IS_UPDATING_STAFFINGS_KANBAN](state: S, payload: Map<string, boolean>): void;
  // [MutationTypes.SET_UPDATED_STAFFINGS_KANBAN_DATA](state: S, payload: any): void;

  [MutationTypes.SET_PAGE_KANBAN](state: S, payload: Map<string, number>): void;
  [MutationTypes.SET_PAGESIZE_KANBAN](state: S, payload: Map<string, number>): void;
  [MutationTypes.SET_TOTAL_KANBAN](state: S, payload: Map<string, number>): void;
  [MutationTypes.SET_SEARCH_KANBAN](state: S, payload: Map<string, string | null>): void;
  [MutationTypes.SET_FILTER_KANBAN](state: S, payload: Map<string, FilterDef[]>): void;
  [MutationTypes.SET_AVAILABLEFIELDS_KANBAN](state: S, payload: Map<string, FieldDef[]>): void;
  [MutationTypes.SET_SORTS_KANBAN](state: S, payload: Map<string, SortDef[]>): void;

  [MutationTypes.SET_STAFFING_CHECKLIST](state: S, payload: StaffingChecklist[]): void;
  [MutationTypes.SET_IS_LOADING_STAFFING_CHECKLIST](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_DELETING_STAFFING_CHECKLIST](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_UPDATING_STAFFING_CHECKLIST](state: S, payload: boolean): void;

  [MutationTypes.SET_STAFFING](state: S, payload: Staffing | null): void;
  [MutationTypes.SET_STAFFING_ERROR](state: S, payload: String | null): void;
  [MutationTypes.SET_STAFFING_ERROR_VALIDATION](state: S, payload: any | null): void;
  [MutationTypes.SET_IS_LOADING_STAFFING](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_STAFFING_LOADED](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CREATING_STAFFING](state: S, payload: boolean): void;
  [MutationTypes.SET_CREATED_STAFFING_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_UPDATING_STAFFING](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_STAFFING_UPDATED](state: S, payload: boolean): void;
  [MutationTypes.SET_UPDATED_STAFFING_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_STAFFING](state: S, payload: boolean): void;

  [MutationTypes.SET_DELETED_STAFFING_SUCCEEDED](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_STAFFING_DATA](state: S, payload: any): void;

  [MutationTypes.SET_STAFFING_TO_PREVIEW_MOD](
    state: S,
    payload: string | null
  ): void;
  [MutationTypes.SET_STAFFING_PREVIEW_OPEN_STATE](
    state: S,
    payload: boolean
  ): void;

  [MutationTypes.SET_STATUSES](state: S, payload: FilterOptionDef[]): void;
  [MutationTypes.SET_IS_LOADING_STATUSES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_STATUSES_LOADED](state: S, payload: boolean): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_STAFFINGS](state: State, staffings: Staffing[]) {
    state.staffings = staffings;
  },
  [MutationTypes.SET_IS_LOADING_STAFFINGS](
    state: State,
    isLoadingStaffings: boolean
  ) {
    state.isLoadingStaffings = isLoadingStaffings;
  },
  [MutationTypes.SET_DELETED_STAFFINGS_DATA](
    state: State,
    deletedStaffingsData: any
  ) {
    state.deletedStaffingsData = deletedStaffingsData;
  },
  [MutationTypes.SET_IS_DELETING_STAFFINGS](
    state: State,
    isDeletingStaffings: boolean
  ) {
    state.isDeletingStaffings = isDeletingStaffings;
  },
  [MutationTypes.SET_IS_UPDATING_STAFFINGS](
    state: State,
    isUpdatingStaffings: boolean
  ) {
    state.isUpdatingStaffings = isUpdatingStaffings;
  },
  [MutationTypes.SET_UPDATED_STAFFINGS_DATA](
    state: State,
    updatedStaffingData: any
  ) {
    state.updatedStaffingData = updatedStaffingData;
  },

  // [MutationTypes.SET_STATUSES](state: State, statuses: StaffingStatus[]) {
  [MutationTypes.SET_STATUSES](state: State, statuses: FilterOptionDef[]) {
    state.statuses = statuses;
    const statusFilter = state.filters.find((f) => f.id === "status");
    if (statusFilter) {
      statusFilter.options = state.statuses;
    }
  },
  [MutationTypes.SET_IS_LOADING_STATUSES](
    state: State,
    isLoadingStatuses: boolean
  ) {
    state.isLoadingStatuses = isLoadingStatuses;
  },
  [MutationTypes.SET_IS_STATUSES_LOADED](
    state: State,
    isStatusesLoaded: boolean
  ) {
    state.isStatusesLoaded = isStatusesLoaded;
  },

  [MutationTypes.SET_PAGE](state: State, page: number) {
    state.page = page;
  },

  [MutationTypes.SET_PAGESIZE](state: State, pageSize: number) {
    state.pageSize = pageSize;
  },

  [MutationTypes.SET_TOTAL](state: State, total: number) {
    state.total = total;
  },

  [MutationTypes.SET_SEARCH](state: State, search: string | null) {
    state.search = search;
  },

  [MutationTypes.SET_SEARCH_CHECKLIST](state: State, search: string | null) {
    state.searchChecklist = search;
  },

  [MutationTypes.SET_FILTER](state: State, filters: FilterDef[]) {
    state.filters = filters;
  },
  [MutationTypes.SET_AVAILABLEFIELDS](
    state: State,
    availableFields: FieldDef[]
  ) {
    state.availableFields = availableFields;
  },

  [MutationTypes.SET_SORTS](state: State, sorts: SortDef[]) {
    state.sorts = sorts;
  },

  [MutationTypes.SET_SORTS_CHECKLIST](state: State, sorts: SortDef[]) {
    state.sortsChecklist = sorts;
  },

  [MutationTypes.SET_STAFFINGS_KANBAN](state: State, staffingsKanban: Map<string, Staffing[]>) {
    state.staffingsKanban = staffingsKanban;
  },
  [MutationTypes.SET_IS_LOADING_STAFFINGS_KANBAN](
    state: State,
    isLoadingStaffingsKanban: Map<string, boolean>
  ) {
    state.isLoadingStaffingsKanban = isLoadingStaffingsKanban;
  },

  [MutationTypes.SET_IS_DELETING_STAFFINGS_KANBAN](
    state: State,
    isDeletingStaffingsKanban: Map<string, boolean>
  ) {
    state.isDeletingStaffingsKanban = isDeletingStaffingsKanban;
  },
  [MutationTypes.SET_IS_UPDATING_STAFFINGS_KANBAN](
    state: State,
    isUpdatingStaffingsKanban: Map<string, boolean>
  ) {
    state.isUpdatingStaffingsKanban = isUpdatingStaffingsKanban;
  },
  // [MutationTypes.SET_UPDATED_STAFFINGS_KANBAN_DATA](
  //   state: State,
  //   updatedStaffingData: any
  // ) {
  //   state.updatedStaffingData = updatedStaffingData;
  // },

  [MutationTypes.SET_PAGE_KANBAN](state: State, pageKanban: Map<string, number>) {
    state.pageKanban = pageKanban;
  },

  [MutationTypes.SET_PAGESIZE_KANBAN](state: State, pageSizeKanban: Map<string, number>) {
    state.pageSizeKanban = pageSizeKanban;
  },

  [MutationTypes.SET_TOTAL_KANBAN](state: State, totalKanban: Map<string, number>) {
    state.totalKanban = totalKanban;
  },

  [MutationTypes.SET_SEARCH_KANBAN](state: State, searchKanban: Map<string, string | null>) {
    state.searchKanban = searchKanban;
  },

  [MutationTypes.SET_FILTER_KANBAN](state: State, filtersKanban: Map<string, FilterDef[]>) {
    state.filtersKanban = filtersKanban;
  },
  [MutationTypes.SET_AVAILABLEFIELDS_KANBAN](
    state: State,
    availableFieldsKanban: Map<string, FieldDef[]>
  ) {
    state.availableFieldsKanban = availableFieldsKanban;
  },

  [MutationTypes.SET_SORTS_KANBAN](state: State, sortsKanban: Map<string, SortDef[]>) {
    state.sortsKanban = sortsKanban;
  },


  [MutationTypes.SET_STAFFING_CHECKLIST](state: State, staffingChecklist: StaffingChecklist[]) {
    state.staffingChecklist = staffingChecklist;
  },
  [MutationTypes.SET_IS_LOADING_STAFFING_CHECKLIST](
      state: State,
      isLoadingStaffingChecklist: boolean
  ) {
    state.isLoadingStaffingChecklist = isLoadingStaffingChecklist;
  },

  [MutationTypes.SET_IS_DELETING_STAFFING_CHECKLIST](
      state: State,
      isDeletingStaffingChecklist: boolean
  ) {
    state.isDeletingStaffingChecklist = isDeletingStaffingChecklist;
  },
  [MutationTypes.SET_IS_UPDATING_STAFFING_CHECKLIST](
      state: State,
      isUpdatingStaffingChecklist: boolean
  ) {
    state.isUpdatingStaffingChecklist = isUpdatingStaffingChecklist;
  },






  [MutationTypes.SET_STAFFING](state: State, staffing: Staffing | null) {
    state.staffing = staffing === null ? null : Staffing.fromObject(staffing);
  },

  [MutationTypes.SET_STAFFING_ERROR](state: State, staffingError: String | null) {
    state.staffingError = staffingError;
  },

  [MutationTypes.SET_STAFFING_ERROR_VALIDATION](state: State, staffingErrorValidation: any | null) {
    state.staffingErrorValidation = staffingErrorValidation;
  },

  [MutationTypes.SET_IS_LOADING_STAFFING](
    state: State,
    isLoadingStaffing: boolean
  ) {
    state.isLoadingStaffing = isLoadingStaffing;
  },
  [MutationTypes.SET_IS_STAFFING_LOADED](
    state: State,
    isStaffingLoaded: boolean
  ) {
    state.isStaffingLoaded = isStaffingLoaded;
  },
  [MutationTypes.SET_IS_CREATING_STAFFING](
    state: State,
    isCreatingStaffing: boolean
  ) {
    state.isCreatingStaffing = isCreatingStaffing;
  },
  [MutationTypes.SET_CREATED_STAFFING_DATA](
    state: State,
    createdStaffingData: any
  ) {
    state.createdStaffingData = createdStaffingData;
  },
  [MutationTypes.SET_IS_UPDATING_STAFFING](
    state: State,
    isUpdatingStaffing: boolean
  ) {
    state.isUpdatingStaffing = isUpdatingStaffing;
  },
  [MutationTypes.SET_IS_STAFFING_UPDATED](
    state: State,
    isStaffingUpdated: boolean
  ) {
    state.isStaffingUpdated = isStaffingUpdated;
  },
  [MutationTypes.SET_UPDATED_STAFFING_DATA](
    state: State,
    updatedStaffingData: any
  ) {
    state.updatedStaffingData = updatedStaffingData;
  },
  [MutationTypes.SET_IS_DELETING_STAFFING](
    state: State,
    isDeletingStaffing: boolean
  ) {
    state.isDeletingStaffing = isDeletingStaffing;
  },
  [MutationTypes.SET_DELETED_STAFFING_SUCCEEDED](
    state: State,
    deletedStaffingSucceeded: boolean
  ) {
    state.deletedStaffingSucceeded = deletedStaffingSucceeded;
  },
  [MutationTypes.SET_DELETED_STAFFING_DATA](
    state: State,
    deletedStaffingData: any
  ) {
    state.deletedStaffingData = deletedStaffingData;
  },
  [MutationTypes.SET_STAFFING_TO_PREVIEW_MOD](
    state: State,
    staffingToPreviewMod: string | null
  ) {
    state.staffingToPreviewMod = staffingToPreviewMod;
  },
  [MutationTypes.SET_STAFFING_PREVIEW_OPEN_STATE](
    state: State,
    staffingPreviewOpenState: boolean
  ) {
    state.staffingPreviewOpenState = staffingPreviewOpenState;
  }

};
