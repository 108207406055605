import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { ServiceCatalogueState as State } from './state';
import {
  ServiceCatalogueItem,
  ServiceCatalogueCategory,
  ServiceCataloguePriority,
  SortDef,
  FilterDef,
  FieldDef
} from '@/types/index';

export type ServiceCatalogueGetters = {

  StateServiceCatalogueItems(state: State): ServiceCatalogueItem[],
  StateIsLoadingServiceCatalogueItem(state: State): boolean,
  StateIsUpdatingServiceCatalogueItem(state: State): boolean,
  StateUpdatedServiceCatalogueItemData(state: State): any,
  StateIsDeletingServiceCatalogueItem(state: State): boolean,
  StateDeletedServiceCatalogueItemData(state: State): any,
  StateDeletedServiceCatalogueItemSucceded(state: State): boolean,

  StatePage(state: State): number,
  StatePageSize(state: State): number,
  StateTotal(state: State): number,
  StateSearch(state: State): string | null,
  StateFilter(state: State): FilterDef[];
  StateAvailableFields(state: State): FieldDef[];
  StateSorts(state: State): SortDef[];

  StateServiceCatalogueItem(state: State): ServiceCatalogueItem | null,
  StateServiceCatalogueItemError(state: State): string | null,
  StateServiceCatalogueItemErrorValidation(state: State): any,
  StateIsLoadingServiceCatalogueItem(state: State): boolean,
  StateIsServiceCatalogueItemLoaded(state: State): boolean,
  StateIsCreatingServiceCatalogueItem(state: State): boolean,
  StateIsServiceCatalogueItemCreated(state: State): boolean,
  StateIsUpdatingServiceCatalogueItem(state: State): boolean,
  StateIsServiceCatalogueItemUpdated(state: State): boolean,

  StateServiceCatalogueToPreviewMod(state: State): string | null,
  StateServiceCataloguePreviewOpenState(state: State): boolean,

  // StateCreatedData(state: State): any,
  // StateUpdatedData(state: State): any,
  // StateIsDeletingServiceCatalogue(state: State): boolean,
  // StateDeletedData(state: State): any,

  StateserviceCatalogueCategories: (state: State) => ServiceCatalogueCategory[],
  StateIsLoadingserviceCatalogueCategories: (state: State) => boolean,
  StateserviceCatalogueCategoriesLoaded: (state: State) => boolean,
  StateIsSavingserviceCatalogueCategories: (state: State) => boolean,

  StateIsLoadingServiceCatalogueCategory: (state: State) => boolean,
  StateServiceCatalogueCategoryLoaded: (state: State) => boolean,
  StateIsSavingServiceCatalogueCategory: (state: State) => boolean,

  StateISServiceCatalogueCategorySaved: (state: State) => boolean,
  StateServiceCatalogueCategorySaveError: (state: State) => string | null,

  StateIsDeletingServiceCatalogueCategory: (state: State) => boolean,

  StateserviceCataloguePriorities: (state: State) => ServiceCataloguePriority[],
  StateIsLoadingserviceCataloguePriorities: (state: State) => boolean,
  StateserviceCataloguePrioritiesLoaded: (state: State) => boolean,
  StateIsSavingserviceCataloguePriorities: (state: State) => boolean,

  StateIsLoadingServiceCataloguePriority: (state: State) => boolean,
  StateServiceCataloguePriorityLoaded: (state: State) => boolean,
  StateIsSavingServiceCataloguePriority: (state: State) => boolean,

  StateISServiceCataloguePrioritySaved: (state: State) => boolean,
  StateServiceCataloguePrioritySaveError: (state: State) => string | null,

  StateIsDeletingServiceCataloguePriority: (state: State) => boolean,
}

export const getters: GetterTree<State, RootState> & ServiceCatalogueGetters = {

  StateServiceCatalogueItems: (state: State) => state.serviceCatalogueItems,
  StateIsLoadingServiceCatalogueItem: (state: State) => state.isLoadingServiceCatalogueItem,
  StateIsUpdatingServiceCatalogueItem: (state: State) => state.isUpdatingServiceCatalogueItem,
  StateUpdatedServiceCatalogueItemData: (state: State) => state.updatedServiceCatalogueItemData,
  StateIsDeletingServiceCatalogueItem: (state: State) => state.isDeletingServiceCatalogueItem,
  StateDeletedServiceCatalogueItemData: (state: State) => state.deletedServiceCatalogueItemData,
  StateDeletedServiceCatalogueItemSucceded: (state: State) => state.deletedServiceCatalogueItemSucceded,
  
  StatePage: (state: State) => state.page,
  StatePageSize: (state: State) => state.pageSize,
  StateTotal: (state: State) => state.total,
  StateSearch: (state: State) => state.search,
  StateFilter: (state: State) => state.filters,
  StateAvailableFields: (state: State) => state.availableFields,
  StateSorts: (state: State) => state.sorts,

  StateServiceCatalogueItem: (state: State) => state.serviceCatalogueItem,
  StateServiceCatalogueItemError: (state: State) => state.serviceCatalogueItemError,
  StateServiceCatalogueItemErrorValidation: (state: State) => state.serviceCatalogueItemErrorValidation,
  // StateIsLoadingServiceCatalogueItem: (state: State) => state.isLoadingServiceCatalogueItem,
  StateIsServiceCatalogueItemLoaded: (state: State) => state.isServiceCatalogueItemLoaded,
  StateIsCreatingServiceCatalogueItem: (state: State) => state.isCreatingServiceCatalogueItem,
  StateIsServiceCatalogueItemCreated: (state: State) => state.isServiceCatalogueItemCreated,
  // StateIsUpdatingServiceCatalogueItem: (state: State) => state.isUpdatingServiceCatalogueItem,
  StateIsServiceCatalogueItemUpdated: (state: State) => state.isServiceCatalogueItemUpdated,
  StateCreatedServiceCatalogueItemData: (state: State) => state.createdServiceCatalogueItemData,
  // StateUpdatedServiceCatalogueItemData: (state: State) => state.updatedServiceCatalogueItemData,
  // StateIsDeletingServiceCatalogueItem: (state: State) => state.isDeletingServiceCatalogueItem,

  StateDeletedServiceCatalogueSucceeded: (state: State) => state.deletedServiceCatalogueItemSucceded,
  StateDeletedData: (state: State) => state.deletedServiceCatalogueItemData,

  StateServiceCatalogueToPreviewMod: (state: State) => state.serviceCatalogueItemToPreviewMod,
  StateServiceCataloguePreviewOpenState: (state: State) => state.serviceCatalogueItemPreviewOpenState,

  StateserviceCatalogueCategories: (state: State) => state.serviceCatalogueCategories,
  StateIsLoadingserviceCatalogueCategories: (state: State) => state.isLoadingServiceCatalogueCategories,
  StateserviceCatalogueCategoriesLoaded: (state: State) => state.isServiceCatalogueCategoriesLoaded,
  StateIsSavingserviceCatalogueCategories: (state: State) => state.isSavingServiceCatalogueCategories,

  StateIsLoadingServiceCatalogueCategory: (state: State) => state.isLoadingServiceCatalogueCategory,
  StateServiceCatalogueCategoryLoaded: (state: State) => state.isServiceCatalogueCategoryLoaded,
  StateIsSavingServiceCatalogueCategory: (state: State) => state.isSavingServiceCatalogueCategory,

  StateISServiceCatalogueCategorySaved: (state: State) => state.isServiceCatalogueCategorySaved,
  StateServiceCatalogueCategorySaveError: (state: State) => state.serviceCatalogueCategorySaveError,

  StateIsDeletingServiceCatalogueCategory: (state: State) => state.isDeletingServiceCatalogueCategory,

  StateserviceCataloguePriorities: (state: State) => state.serviceCataloguePriorities,
  StateIsLoadingserviceCataloguePriorities: (state: State) => state.isLoadingServiceCataloguePriorities,
  StateserviceCataloguePrioritiesLoaded: (state: State) => state.isServiceCataloguePrioritiesLoaded,
  StateIsSavingserviceCataloguePriorities: (state: State) => state.isSavingServiceCataloguePriorities,

  StateIsLoadingServiceCataloguePriority: (state: State) => state.isLoadingServiceCataloguePriority,
  StateServiceCataloguePriorityLoaded: (state: State) => state.isServiceCataloguePriorityLoaded,
  StateIsSavingServiceCataloguePriority: (state: State) => state.isSavingServiceCataloguePriority,

  StateISServiceCataloguePrioritySaved: (state: State) => state.isServiceCataloguePrioritySaved,
  StateServiceCataloguePrioritySaveError: (state: State) => state.serviceCataloguePrioritySaveError,

  StateIsDeletingServiceCataloguePriority: (state: State) => state.isDeletingServiceCataloguePriority,
};
