import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { omit } from "lodash";
// import { State } from './state';
// import {
//   StaffingActionTypes,
//   StaffingMutationTypes,
//   StaffingState as State
// } from "@/types/index";
import { StaffingActionTypes } from "./action-types";
import { StaffingMutationTypes } from "./mutation-types";
import { StaffingState as State } from "./state";
// import axios from "axios";
import { HttpClient as http, AxiosError, AxiosResponse } from "@/util/ZebritAxios";
import { Config } from "../../../config";
import {
  Staffing,
  FieldDef,
  IGetStaffing,
  IGetStaffingChecklist,
  SortDef,
  FilterDef,
  StaffingListFilters,
  StaffingListFields,
  StaffingChecklist
} from "@/types/index";
import { Mutations } from "./mutations";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [StaffingActionTypes.GET_STAFFINGS_ACTION]({
    commit,
  }: AugmentedActionContext): // payload: {
  //   // page: number;
  //   // page_size: number;
  //   category: string | null;
  //   // search: string | null;
  // }
  void;
  [StaffingActionTypes.GET_STAFFINGS_KANBAN_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      statusMapKey: string;
    }
  ): void;
  // [StaffingActionTypes.GET_STAFFINGS_CHARTS_ACTION]({ commit }: AugmentedActionContext): void;
  [StaffingActionTypes.GET_STAFFING_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetStaffing
  ): void;
  [StaffingActionTypes.SAVE_STAFFING_ACTION](
    { commit }: AugmentedActionContext,
    payload: Staffing
  ): void;
  [StaffingActionTypes.CHANGE_STAFFING_STATUS_ORDER_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      staffingId: string;
      status: string;
      statusOrder: number;
    }
  ): void;
  [StaffingActionTypes.DELETE_STAFFING_ACTION](
    { commit }: AugmentedActionContext,
    payload: Staffing
  ): void;
  [StaffingActionTypes.SET_STAFFING_ACTION](
    { commit }: AugmentedActionContext,
    payload: Staffing | null
  ): void;
  [StaffingActionTypes.SET_STAFFINGS_ACTION](
    { commit }: AugmentedActionContext,
    payload: Staffing[]
  ): void;

  [StaffingActionTypes.GET_STAFFING_CHECKLIST_ACTION](
      { commit }: AugmentedActionContext,
      payload: IGetStaffingChecklist
  ): void;
  [StaffingActionTypes.SET_STAFFING_CHECKLIST_ACTION](
      { commit }: AugmentedActionContext,
      payload: StaffingChecklist[]
  ): void;
  [StaffingActionTypes.SAVE_STAFFING_CHECKLIST_ACTION](
      { commit }: AugmentedActionContext,
      payload: StaffingChecklist
  ): void;
  [StaffingActionTypes.DELETE_STAFFING_CHECKLIST_ACTION](
      { commit }: AugmentedActionContext,
      payload: StaffingChecklist
  ): void;

  [StaffingActionTypes.SET_PAGE]({ commit }: AugmentedActionContext, payload: number): void;
  [StaffingActionTypes.SET_PAGESIZE](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [StaffingActionTypes.SET_TOTAL](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [StaffingActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [StaffingActionTypes.SET_SEARCH_CHECKLIST](
      { commit }: AugmentedActionContext,
      payload: string | null
  ): void;
  [StaffingActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ): void;
  [StaffingActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ): void;
  [StaffingActionTypes.SET_SORTS](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ): void;
  [StaffingActionTypes.SET_SORTS_CHECKLIST](
      { commit }: AugmentedActionContext,
      payload: SortDef[]
  ): void;

  [StaffingActionTypes.SET_STAFFING_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [StaffingActionTypes.SET_STAFFING_PREVIEW_OPEN_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

}

// const getters = mapGetters({
//     page: "ticket/StatePage",
//     page_size: "ticket/StatePageSize",
//     total: "ticket/StateTotal",
//     search: "ticket/StateSearch",
//     filter: "ticket/StateFilter"
// });

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [StaffingActionTypes.GET_STAFFINGS_ACTION]({
    commit,
    getters,
  }: AugmentedActionContext) {
    commit(StaffingMutationTypes.SET_IS_LOADING_STAFFINGS, true);
    const page = getters.StatePage; // StaffingGetters.StatePage() || 1;
    const page_size = getters.StatePageSize; // StaffingGetters.StatePageSize || 10;
    const search = getters.StateSearch; // StaffingGetters.StateSearch || 1;
    const filter = getters.StateFilter;
    const sorts = getters.StateSorts;

    const params: any = {
      $page: page, //payload && payload.page ? payload.page : 1,
      $limit: page_size, //payload && payload.page_size ? payload.page_size : 10,
      //category: payload && payload.category ? payload.category : null,
      // $text: search ? search : null, //payload && payload.search ? payload.search : null
    };

    // if (search) params.$text = search;
    if (search) {
      params.$term = search;
      const searchFields = StaffingListFields.filter((tf) => tf.searchable === true).map(
        (tf) => tf.id
      );
      params.$searchFields = searchFields;
    }

    // debugger; // eslint-disable-line
    StaffingListFilters.forEach((f) => {
      const _f = filter.find((__f: FilterDef) => __f.id == f.id);

      if (_f && _f.value !== f.value) {
        if (typeof _f.value == "string") {
          // debugger; // eslint-disable-line
          params[_f.queryParam] = _f.value;
        } else if (["type", "severity", "status"].indexOf(f.id) > -1) {
          if (_f.value._id !== null) params[_f.queryParam] = _f.value.name;
        } else {
          params[_f.queryParam] = _f.value._id;
        }
      }
    });

    // debugger; // eslint-disable-line
    if (sorts && sorts.length > 0) {
      params["$sort"] = (sorts[0].direction == "desc" ? "-" : "") + sorts[0].field.id;
    }

    // &organisation=636bcea8258a4899e28e86b0
    await http
      .get(
        //Config.api.tickets.tickets + "?$embed=assignee&$embed=submittedBy&$embed=organisation",
        Config.api.staffings.staffings,
        params
      )
      .then((res: AxiosResponse) => {
        res.data.docs.forEach((doc: any) => {
          if (doc.assignee) {
            doc.assignee = `${doc.assigneeFirstName} ${doc.assigneeLastName}`;
          }
          if (doc.submittedBy) {
            doc.submittedBy = `${doc.submittedByFirstName} ${doc.submittedByLastName}`;
          }
        });

        commit(StaffingMutationTypes.SET_STAFFINGS, res.data.docs);
        commit(StaffingMutationTypes.SET_PAGE, res.data.pages.current);
        commit(StaffingMutationTypes.SET_PAGESIZE, res.data.items.limit);
        commit(StaffingMutationTypes.SET_TOTAL, res.data.items.total);
        commit(StaffingMutationTypes.SET_IS_LOADING_STAFFINGS, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(StaffingMutationTypes.SET_IS_LOADING_STAFFINGS, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [StaffingActionTypes.GET_STAFFINGS_KANBAN_ACTION](
    { commit, getters }: AugmentedActionContext,
    payload: {
      statusMapKey: string;
    }
  ) {
    const statusMapKey = payload.statusMapKey;

    // commit(StaffingMutationTypes.SET_IS_LOADING_STAFFINGS_KANBAN, true);

    const isLoadingStaffingsKanban = new Map<string, boolean>(
      JSON.parse(JSON.stringify(Array.from(getters.StateIsLoadingStaffingsKanban)))
    );
    isLoadingStaffingsKanban.set(statusMapKey, true);
    commit(StaffingMutationTypes.SET_IS_LOADING_STAFFINGS_KANBAN, isLoadingStaffingsKanban);

    // const page = getters.StatePageKanban.get(statusMapKey); // StaffingGetters.StatePage() || 1;
    // const page_size = getters.StatePageSizeKanban.get(statusMapKey); // StaffingGetters.StatePageSize || 10;
    const search = getters.StateSearchKanban.get(statusMapKey) || null; // StaffingGetters.StateSearch || 1;
    const filter = getters.StateFilterKanban.get(statusMapKey) || [];
    // const sorts = getters.StateSortsKanban.get(statusMapKey) || [];

    const params: any = {
      // $page: page, //payload && payload.page ? payload.page : 1,
      // $limit: page_size, //payload && payload.page_size ? payload.page_size : 10,
      //category: payload && payload.category ? payload.category : null,
      // $text: search ? search : null, //payload && payload.search ? payload.search : null
    };

    // if (search) params.$text = search;
    if (search) {
      params.$term = search;
      const searchFields = StaffingListFields.filter((tf) => tf.searchable === true).map(
        (tf) => tf.id
      );
      params.$searchFields = searchFields;
    }

    // debugger; // eslint-disable-line
    StaffingListFilters.forEach((f) => {
      const _f = filter.find((__f: FilterDef) => __f.id == f.id);

      if (_f && _f.value !== f.value) {
        if (typeof _f.value == "string") {
          // debugger; // eslint-disable-line
          params[_f.queryParam] = _f.value;
        } else if (["type", "severity", "status"].indexOf(f.id) > -1) {
          if (_f.value._id !== null) params[_f.queryParam] = _f.value.name;
        } else {
          params[_f.queryParam] = _f.value._id;
        }
      }
    });

    // debugger; // eslint-disable-line
    // if (sorts && sorts.length > 0) {
    //   params["$sort"] = (sorts[0].direction == "desc" ? "-" : "") + sorts[0].field.id;
    // }
    params.$sort = "statusOrder";
    params.status = statusMapKey;
    // &organisation=636bcea8258a4899e28e86b0
    params.$embed = 'checklist'

    await http
      .get(
        //Config.api.tickets.tickets + "?$embed=assignee&$embed=submittedBy&$embed=organisation",
        Config.api.staffings.staffings,
        params
      )
      .then((res: AxiosResponse) => {
        res.data.docs.forEach((doc: any) => {
          if (doc.assignee) {
            doc.assignee = `${doc.assigneeFirstName} ${doc.assigneeLastName}`;
          }
          if (doc.submittedBy) {
            doc.submittedBy = `${doc.submittedByFirstName} ${doc.submittedByLastName}`;
          }
        });

        let staffingsKanban = new Map<string, Staffing[]>(
          JSON.parse(JSON.stringify(Array.from(getters.StateStaffingsKanban)))
        );
        staffingsKanban.set(statusMapKey, res.data.docs);
        // this took me lot of time :(
        staffingsKanban = new Map([...staffingsKanban.entries()].sort());
        commit(StaffingMutationTypes.SET_STAFFINGS_KANBAN, staffingsKanban);

        const pageKanban = new Map<string, number>(
          JSON.parse(JSON.stringify(Array.from(getters.StatePageKanban)))
        );
        pageKanban.set(statusMapKey, res.data.pages.current);
        commit(StaffingMutationTypes.SET_PAGE_KANBAN, pageKanban);

        const pageSizeKanban = new Map<string, number>(
          JSON.parse(JSON.stringify(Array.from(getters.StatePageSizeKanban)))
        );
        pageSizeKanban.set(statusMapKey, res.data.items.limit);
        commit(StaffingMutationTypes.SET_PAGESIZE_KANBAN, pageSizeKanban);

        const totalKanban = new Map<string, number>(
          JSON.parse(JSON.stringify(Array.from(getters.StateTotalKanban)))
        );
        totalKanban.set(statusMapKey, res.data.items.total);
        commit(StaffingMutationTypes.SET_TOTAL_KANBAN, totalKanban);

        const isLoadingStaffingsKanban = new Map<string, boolean>(
          JSON.parse(JSON.stringify(Array.from(getters.StateIsLoadingStaffingsKanban)))
        );
        isLoadingStaffingsKanban.set(statusMapKey, false);
        commit(StaffingMutationTypes.SET_IS_LOADING_STAFFINGS_KANBAN, isLoadingStaffingsKanban);

        // if(getters.StateStaffingsKanban.has(statusMapKey)){
        //   const staffingsKanban = getters.StateStaffingsKanban.get(statusMapKey);
        //   staffingsKanban.set('statusMapKey', res.data.docs);
        // } else {
        //   getters.StateStaffingsKanban.set
        //   staffingsKanban.set('statusMapKey', res.data.docs);
        // }

        // commit(StaffingMutationTypes.SET_STAFFINGS_KANBAN, res.data.docs);
        // commit(StaffingMutationTypes.SET_STAFFINGS_KANBAN, res.data.docs);
        // commit(StaffingMutationTypes.SET_PAGE, res.data.pages.current);
        // commit(StaffingMutationTypes.SET_PAGESIZE, res.data.items.limit);
        // commit(StaffingMutationTypes.SET_TOTAL, res.data.items.total);
        // commit(StaffingMutationTypes.SET_IS_LOADING_STAFFINGS, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        // commit(StaffingMutationTypes.SET_IS_LOADING_STAFFINGS, false);

        const isLoadingStaffingsKanban = new Map<string, boolean>(
          JSON.parse(JSON.stringify(Array.from(getters.StateIsLoadingStaffingsKanban)))
        );
        isLoadingStaffingsKanban.set(statusMapKey, false);
        commit(StaffingMutationTypes.SET_IS_LOADING_STAFFINGS_KANBAN, isLoadingStaffingsKanban);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [StaffingActionTypes.GET_STAFFING_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetStaffing
  ) {
    //commit(StaffingMutationTypes.SET_IS_LOADING_STAFFING, true);
    commit(StaffingMutationTypes.SET_IS_LOADING_STAFFING, true);
    // console.log(Config);
    // console.log(Config.api);
    // console.log(Config.api.ticket);
    // console.log(Config.api.ticket.get);

    await http
      .get(Config.api.staffing.get.replace("{id}", payload.id.toString())) // `api/ticket/${id}`)
      .then((res: AxiosResponse) => {
        commit(StaffingMutationTypes.SET_STAFFING, res.data);
        commit(StaffingMutationTypes.SET_IS_LOADING_STAFFING, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(StaffingMutationTypes.SET_IS_LOADING_STAFFING, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [StaffingActionTypes.SAVE_STAFFING_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: Staffing
  ) {
    const actionType = payload._id
      ? StaffingMutationTypes.SET_IS_UPDATING_STAFFING
      : StaffingMutationTypes.SET_IS_CREATING_STAFFING;
    commit(actionType, true);
    const endpint_url =
      Config.api.staffings.staffing +
      (actionType === StaffingMutationTypes.SET_IS_UPDATING_STAFFING
        ? `/${payload._id}`
        : "");

    let _payload = JSON.parse(JSON.stringify(payload));
    _payload.assignee = _payload.assigneeId;
    _payload.submittedBy = _payload.submittedById;
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== null)
    );
    _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== undefined)
    );
    // debugger; // eslint-disable-line
    _payload = omit(_payload, [
      "assigneeId",
      "submittedById",
      "submittedByFirstName",
      "submittedByLastName",
      "submittedByAvatar",
      "organisationName",
      "organisationCode",
      "createdAt",
      "createdBy",
      "assigneeFirstName",
      "assigneeLastName",
      "assigneeAvatar",
      "updatedAt",
      "updatedBy",
    ]);

    await http
      .request(payload._id ? "PUT" : "POST", endpint_url, _payload)
      // .put(endpint_url, payload)
      .then((res: any) => {
        if (res.isAxiosError) {
          commit(StaffingMutationTypes.SET_STAFFING_ERROR, res.response.data.message);
          commit(
            StaffingMutationTypes.SET_STAFFING_ERROR_VALIDATION,
            res.response.data.validation
          );
          commit(actionType, false);
        } else {
          commit(StaffingMutationTypes.SET_STAFFING_ERROR, null);
          commit(StaffingMutationTypes.SET_STAFFING_ERROR_VALIDATION, null);
          commit(StaffingMutationTypes.SET_STAFFING, res.data);
          dispatch(StaffingActionTypes.SET_STAFFING_PREVIEW_OPEN_STATE, false);
          commit(actionType, false);
          // dispatch(StaffingActionTypes.GET_STAFFINGS_ACTION);
        }
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(actionType, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [StaffingActionTypes.CHANGE_STAFFING_STATUS_ORDER_ACTION](
      { commit, dispatch }: AugmentedActionContext,
      payload: {
        staffingId: string;
        status: string;
        statusOrder: number;
      }
  ) {
    const endpint_url = Config.api.staffings.staffingStatusOrder;
    const _payload = JSON.parse(JSON.stringify(payload));

    await http
        .request("PUT", endpint_url, _payload)
        // .put(endpint_url, payload)
        .then((res: any) => {
          if (res.isAxiosError) {
            // dispatch(TicketActionTypes.GET_TICKETS_ACTION);
            // commit(TicketMutationTypes.SET_TICKET_ERROR, res.response.data.message);
            // commit(
            //   TicketMutationTypes.SET_TICKET_ERROR_VALIDATION,
            //   res.response.data.validation
            // );
            // commit(actionType, false);
          } else {
            // commit(TicketMutationTypes.SET_TICKET_ERROR, null);
            // commit(TicketMutationTypes.SET_TICKET_ERROR_VALIDATION, null);
            // commit(TicketMutationTypes.SET_TICKET, res.data);
            // dispatch(TicketActionTypes.SET_TICKET_PREVIEW_OPEN_STATE, false);
            // commit(actionType, false);
            // dispatch(TicketActionTypes.GET_TICKETS_KANBAN_ACTION);
          }
        })
        .catch((err: AxiosError) => {
          console.log("error", err);
          // commit(actionType, false);
        });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [StaffingActionTypes.DELETE_STAFFING_ACTION](
    { commit }: AugmentedActionContext,
    payload: Staffing
  ) {
    commit(StaffingMutationTypes.SET_IS_DELETING_STAFFING, true);
    // const endpint_url = `${Config.api.tickets.ticket}/${payload._id}`;

    await http
      .request("DELETE", Config.api.staffings.staffing, [payload])
      // .put(endpint_url, payload)
      .then(() => {
        // commit(StaffingMutationTypes.SET_STAFFING, res.data);
        commit(StaffingMutationTypes.SET_DELETED_STAFFING_DATA, null);
        commit(StaffingMutationTypes.SET_DELETED_STAFFING_SUCCEEDED, true);
        commit(StaffingMutationTypes.SET_IS_DELETING_STAFFING, false);
        // dispatch(StaffingActionTypes.GET_STAFFINGS_ACTION);
      })
      .catch((err: any) => {
        console.log("error", err);
        commit(StaffingMutationTypes.SET_DELETED_STAFFING_DATA, err.data.message);
        commit(StaffingMutationTypes.SET_DELETED_STAFFING_SUCCEEDED, false);
        commit(StaffingMutationTypes.SET_IS_DELETING_STAFFING, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [StaffingActionTypes.SET_STAFFING_ACTION](
    { commit }: AugmentedActionContext,
    payload: Staffing | null
  ) {
    // const actionType = (payload.ticket._id) ?
    //   StaffingMutationTypes.SET_IS_CREATING_STAFFING
    //   :
    //   StaffingMutationTypes.SET_IS_UPDATING_STAFFING;
    // commit(actionType, true);

    commit(StaffingMutationTypes.SET_STAFFING, payload);
    // commit(actionType, false);
  },
  async [StaffingActionTypes.SET_STAFFINGS_ACTION](
    { commit }: AugmentedActionContext,
    payload: Staffing[]
  ) {
    // const actionType = (payload.ticket._id) ?
    //   StaffingMutationTypes.SET_IS_CREATING_STAFFING
    //   :
    //   StaffingMutationTypes.SET_IS_UPDATING_STAFFING;
    // commit(actionType, true);

    commit(StaffingMutationTypes.SET_STAFFINGS, payload);
    // commit(actionType, false);
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // async [StaffingActionTypes.GET_CATEGORIES_ACTION](
  //     { commit }: AugmentedActionContext
  // ) {
  //     commit(StaffingMutationTypes.SET_IS_LOADING_CATEGORIES, true);
  //     await http.get('api/tickets/categories')
  //         .then(res => {
  //             commit(StaffingMutationTypes.SET_CATEGORIES, res.data)
  //             commit(StaffingMutationTypes.SET_IS_LOADING_CATEGORIES, false);
  //             commit(StaffingMutationTypes.SET_IS_CATEGORIES_LOADED, true);
  //         })
  //         .catch(err => {
  //             console.log('error', err);
  //             commit(StaffingMutationTypes.SET_CATEGORIES, [])
  //             commit(StaffingMutationTypes.SET_IS_LOADING_CATEGORIES, false);
  //             commit(StaffingMutationTypes.SET_IS_CATEGORIES_LOADED, false);
  //         })
  // },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  /* async [StaffingActionTypes.GET_MANUFACTURERS_ACTION](
      { commit }: AugmentedActionContext
    ) {
      commit(StaffingMutationTypes.SET_IS_LOADING_MANUFACTURERS, true);
      await http.get('api/tickets/manufacturers')
        .then(res => {
          commit(StaffingMutationTypes.SET_MANUFACTURERS, res.data)
          commit(StaffingMutationTypes.SET_IS_LOADING_MANUFACTURERS, false);
          commit(StaffingMutationTypes.SET_IS_MANUFACTURERS_LOADED, true);
        })
        .catch(err => {
          console.log('error', err);
          commit(StaffingMutationTypes.SET_MANUFACTURERS, []);
          commit(StaffingMutationTypes.SET_IS_LOADING_MANUFACTURERS, false);
          commit(StaffingMutationTypes.SET_IS_MANUFACTURERS_LOADED, false);
        })
    }, */

  async [StaffingActionTypes.GET_STATUSES_ACTION]({ commit }: AugmentedActionContext) {
    commit(StaffingMutationTypes.SET_IS_LOADING_STATUSES, true);
    await http
      .get(Config.api.staffings.staffingStatuses)
      .then((res: AxiosResponse) => {
        commit(StaffingMutationTypes.SET_STATUSES, res.data.docs);
        commit(StaffingMutationTypes.SET_IS_LOADING_STATUSES, false);

        commit(StaffingMutationTypes.SET_IS_STATUSES_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(StaffingMutationTypes.SET_STATUSES, []);
        commit(StaffingMutationTypes.SET_IS_LOADING_STATUSES, false);
        commit(StaffingMutationTypes.SET_IS_STATUSES_LOADED, false);
      });
  },

  async [StaffingActionTypes.GET_STAFFING_CHECKLIST_ACTION](
      { commit }: AugmentedActionContext,
      payload: IGetStaffingChecklist
  ){
    commit(StaffingMutationTypes.SET_IS_LOADING_STAFFING_CHECKLIST, true);
    // const page = getters.StatePage; // StaffingGetters.StatePage() || 1;
    // const page_size = getters.StatePageSize; // StaffingGetters.StatePageSize || 10;
    // const search = getters.StateSearch; // StaffingGetters.StateSearch || 1;
    // const filter = getters.StateFilter;
    // const sorts = getters.StateSorts;

    // &organisation=636bcea8258a4899e28e86b0
    await http
        .get(Config.api.staffing.checkList.replace("{id}", payload.staffing_id.toString()))
        .then((res: AxiosResponse) => {

          // res.data.docs.forEach((doc: any) => {
          //   if (doc.assignee) {
          //     doc.assignee = `${doc.assigneeFirstName} ${doc.assigneeLastName}`;
          //   }
          //   if (doc.submittedBy) {
          //     doc.submittedBy = `${doc.submittedByFirstName} ${doc.submittedByLastName}`;
          //   }
          // });

          commit(StaffingMutationTypes.SET_STAFFING_CHECKLIST, res.data.docs);
          commit(StaffingMutationTypes.SET_IS_LOADING_STAFFING_CHECKLIST, false);
        })
        .catch((err: AxiosError) => {
          console.log("error", err);
          commit(StaffingMutationTypes.SET_IS_LOADING_STAFFING_CHECKLIST, false);
        });
  },
  async [StaffingActionTypes.SET_STAFFING_CHECKLIST_ACTION](
      { commit }: AugmentedActionContext,
      payload: StaffingChecklist[]
  ) {
    commit(StaffingMutationTypes.SET_STAFFING_CHECKLIST, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [StaffingActionTypes.SAVE_STAFFING_CHECKLIST_ACTION](
      { commit, dispatch }: AugmentedActionContext,
      payload: StaffingChecklist
  ) {
    const endpint_url = Config.api.staffing.checkListUpdate.replace("{id}", payload._id.toString());
    const _payload = JSON.parse(JSON.stringify({ status: (payload.status === 'done')? 'notDone':'done' }));
    await http
        .request("PUT", endpint_url, _payload)
        .then((res: any) => {
          if (res.isAxiosError) {
            commit(StaffingMutationTypes.SET_STAFFING_ERROR, res.response.data.message);
            commit(StaffingMutationTypes.SET_STAFFING_ERROR_VALIDATION, res.response.data.validation);
          } else {
            commit(StaffingMutationTypes.SET_STAFFING_ERROR, null);
            commit(StaffingMutationTypes.SET_STAFFING_ERROR_VALIDATION, null);
            dispatch(StaffingActionTypes.GET_STAFFING_CHECKLIST_ACTION, { staffing_id: payload.staffing });
          }
        })
        .catch((err: AxiosError) => {
          console.log("error", err);
        });
  },

  async [StaffingActionTypes.DELETE_STAFFING_CHECKLIST_ACTION](
      { commit }: AugmentedActionContext,
      payload: StaffingChecklist
  ){
    // @todo: delete a the checklist item
  },

  [StaffingActionTypes.SET_PAGE]({ commit }: AugmentedActionContext, payload: number) {
    commit(StaffingMutationTypes.SET_PAGE, payload);
  },

  [StaffingActionTypes.SET_PAGESIZE]({ commit }: AugmentedActionContext, payload: number) {
    commit(StaffingMutationTypes.SET_PAGESIZE, payload);
  },

  [StaffingActionTypes.SET_TOTAL]({ commit }: AugmentedActionContext, payload: number) {
    commit(StaffingMutationTypes.SET_TOTAL, payload);
  },

  [StaffingActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(StaffingMutationTypes.SET_SEARCH, payload);
  },

  [StaffingActionTypes.SET_SEARCH_CHECKLIST](
      { commit }: AugmentedActionContext,
      payload: string | null
  ) {
    commit(StaffingMutationTypes.SET_SEARCH_CHECKLIST, payload);
  },

  [StaffingActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ) {
    commit(StaffingMutationTypes.SET_FILTER, payload);
  },

  [StaffingActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ) {
    commit(StaffingMutationTypes.SET_AVAILABLEFIELDS, payload);
  },

  [StaffingActionTypes.SET_SORTS]({ commit }: AugmentedActionContext, payload: SortDef[]) {
    commit(StaffingMutationTypes.SET_SORTS, payload);
  },

  [StaffingActionTypes.SET_SORTS_CHECKLIST]({ commit }: AugmentedActionContext, payload: SortDef[]) {
    commit(StaffingMutationTypes.SET_SORTS_CHECKLIST, payload);
  },

  [StaffingActionTypes.SET_STAFFING_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(StaffingMutationTypes.SET_STAFFING_TO_PREVIEW_MOD, payload);
  },

  [StaffingActionTypes.SET_STAFFING_PREVIEW_OPEN_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(StaffingMutationTypes.SET_STAFFING_PREVIEW_OPEN_STATE, payload);
  },

};
